.swiper {
  max-width: 99.7vw;
  overflow-x: hidden;
}

.swiper-button-prev {
  background-image: url("/public/assets/icons/bestock/slider-prev.svg");
  height: 40px;
  width: 40px;
  color: transparent;
  left: calc(50% - 555px);
}

.swiper-button-next {
  background-image: url("/public/assets/icons/bestock/slider-next.svg");
  height: 40px;
  width: 40px;
  color: transparent;
  right: calc(50% - 555px);
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.swiper-slide {
  width: fit-content;
}

.swiper-slide-prev > div:first-child,
.swiper-slide-next > div:first-child {
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
}

.swiper-slide-active > div:first-child {
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 15%, rgba(0, 0, 0, 0) 100%) no-repeat;
}

.swiper-pagination {
  width: 100%;
  height: 6px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 7px;
}

.swiper-pagination-bullet {
  display: block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #5a6066;
}

.swiper-pagination-bullet-active {
  display: block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #e1e6ea;
}

/* 모바일 반응형 */
@media screen and (max-width: 487px) {
  .swiper-button-prev {
    left: calc(50% - 166px);
  }

  .swiper-button-next {
    right: calc(50% - 166px);
  }
  .swiper-slide {
    width: 335px;
    height: 450px;
  }
}
