/* 토스트팝업 설정 */
.toast {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28.5px;
  text-align: center;
  color: #f1f1f1;
}

.Toastify__toast-container {
  padding: 0px !important;
  width: unset !important;
}

.Toastify__toast-container--top-center {
  top: 100px !important;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Toastify__toast-theme--light {
  background: #565656 !important;
  border-radius: 100px !important;
  font-family: "Pretendard" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff !important;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.2);
}

.Toastify__toast-body {
  justify-content: center;
  gap: 5px;
  padding: 6px 30px !important;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: none !important;
}

.Toastify__toast {
  min-width: 400px;
  width: auto;
  padding: 0 !important;
  min-height: 53px !important;
  height: auto;
  /* margin-bottom: 0px !important; */
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  margin-inline-end: 0 !important;
  width: 6px !important;
  height: 6px;
}

.Toastify {
  z-index: 999999999;
  position: absolute;
}

/* 인풋 커스텀 */
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

input[type="date"]::before {
  content: attr(data-placeholder);
  width: 100%;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

input[type="date"]:valid::before {
  display: none;
}

input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* 비디오 플레이어 */
.videoPlayer {
  display: none;
}

.videoPlayer video {
  object-fit: cover;
}
